<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Sales Channel detail</h1>
        </div>
        <div class="title-panel mt-3">Sales Channel Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6">
                <InputText
                  class="mb-4"
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  isRequired
                  v-model="form.saleschannel.name"
                  :v="$v.form.saleschannel.name"
                />
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <UploadFile
                      textFloat="Cover Image"
                      placeholder="Please choose file"
                      format="all"
                      :fileName="form.saleschannel.image_url"
                      v-model="form.saleschannel.image_url"
                      name="thumbnail"
                      text="*Please upload only .png, .jpg image with ratio 1:1"
                      isRequired
                      :accept="acceptType"
                      :v="$v.form.saleschannel.image_url"
                      v-on:onFileChange="onImageChange"
                      v-on:delete="deleteImage"
                    />
                  </b-col>
                  <b-col md="12">
                    <div
                      class="preview-box b-contain"
                      v-bind:style="{ 'background-image': 'url(' + showPreview + ')' }"
                    >
                      <img src="@/assets/icons/loading.svg" class="loading" alt="loading" v-if="isLoadingImage" />
                      <font-awesome-icon
                        icon="times-circle"
                        class="text-secondary delete-icon pointer"
                        v-if="form.saleschannel.image_url"
                        @click="deleteImage"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <FooterAction @submit="checkForm(0)" routePath="/saleschannel" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText"
import { required } from "vuelidate/lib/validators"
import UploadFile from "@/components/inputs/UploadFile"
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "ProductDetails",
  components: {
    InputText,
    UploadFile,
    OtherLoading
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isDisable: false,
      isEdit: false,
      isSuccess: false,
      coverImgType: 1,
      showPreview: "",
      isLoadingImage: false,
      acceptType: 'image/jpeg, image/png',
      form: {
        saleschannel: {
          id: 0,
          name: "",
          image_url: "",
        }
      },
      image: {
        height: "",
        width: "",
      },
    }
  },
  validations: {
    form: {
      saleschannel: {
        name: { required },
        image_url: { required }
      }
    }
  },
  created: async function () {
    if (this.id !== '0') {
      this.GetSalesChannelByID()
    }
  },
  methods: {
    GetSalesChannelByID: async function() {
      await this.$store.dispatch('ActionGetSalesChannelByID', this.id)
      var data = await this.$store.state.moduleSalesChannel.stateGetSalesChannelByID
      if (data.result == 1) {
        this.form.saleschannel = data.detail[0]
        this.showPreview = data.detail[0].image_url
      }
    },
    onImageChange(val) {
      this.isLoadingImage = true
      this.isDisable = true

      var reader = new FileReader()
      reader.readAsDataURL(val)
      reader.onload = async () => {
        let img = new Image()
        img.onload = () => {
          this.image.width = img.width
          this.image.height = img.height
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            })
            this.deleteImage()
            this.isLoadingImage = false
            this.isDisable = false
          } else {
            this.setDataImage(reader.result)
          }
        }
        img.src = await reader.result

      }
    },
    async setDataImage (base64) {
      var url = await this.saveImagetoDb(base64, false)
      this.isLoadingImage = false
      this.isDisable = false
      this.showPreview = url
      this.form.saleschannel.image_url = url
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      }
      await this.$store.dispatch("ActionUploadImage", form)
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage
      if (data.result == 1) {
        return data.detail
      } else {
        alert("Unsuccess!")
      }
    },
    deleteImage() {
      this.form.saleschannel.image_url = null
      this.showPreview = null
    },
    checkForm: async function() {
      this.$v.form.$touch()
      if (this.$v.form.$error) {
        return
      }
      this.submit()
    },
    submit: async function () {
      this.isDisable = true
      let data = null
      if (this.id !== '0') {
        await this.$store.dispatch('ActionUpdatetSalesChannel', this.form.saleschannel)
        data = await this.$store.state.moduleSalesChannel.stateUpdatetSalesChannel
        if (data.result == 1) {
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function() {
              window.location.href = "/saleschannel"
          })
          this.isDisable = false
        }
      } else {
        await this.$store.dispatch('ActionCreateSalesChannel', this.form.saleschannel)
        data = await this.$store.state.moduleSalesChannel.stateCreateSalesChannel
        if (data.result == 1) {
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function() {
              window.location.href = "/saleschannel"
          })
          this.isDisable = false
        }
      }
    },
  },
}
</script>
